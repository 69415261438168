import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Professor } from 'src/app/models/professor';
import { Opcao } from 'src/app/models/opcao';
import moment from 'moment';
import { FaturamentoProfessor } from 'src/app/models/faturamento-professor';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class ProfessorService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  getDataFormatada(ano: number, mes: number) {
    return moment()
      .locale('pt-br')
      .set('year', ano)
      .set('month', mes - 1)
      .date(1)
      .format('YYYY-MM-DD');
  }

  getProfessores(pageNumber: number, pageSize: number) {
    return this.http.get<Professor[]>(`${this.baseUrl}/professores?${stringify({ pageNumber, pageSize })}`, {
      observe: 'response',
    });
  }

  getOpcoesProfessor() {
    return this.http.get<Opcao[]>(`${this.baseUrl}/professores/opcoes-professor`);
  }

  getProfessor(id: number) {
    return this.http.get<Professor>(`${this.baseUrl}/professores/${id}`);
  }

  salvar(professor: Professor) {
    return this.http.post(`${this.baseUrl}/professores`, professor);
  }

  atualizar(professor: Professor) {
    return this.http.put(`${this.baseUrl}/professores/${professor.id}`, professor);
  }

  remover(id: number) {
    return this.http.delete(`${this.baseUrl}/professores/${id}`);
  }

  getAlunosProfessor(professor: number, curso: number) {
    const params = new HttpParams().set('curso', String(curso));
    return this.http.get<Professor[]>(`${this.baseUrl}/professores/${professor}/alunos`, { params });
  }

  getPagamentoAlunos(professor: any, site: any, mes: any, ano: any) {
    const data = this.getDataFormatada(ano, mes);
    return this.http.get<any[]>(`${this.baseUrl}/professores/pagamento-alunos?${stringify({ professor, site, data })}`);
  }

  getFaturamento(params: any) {
    return this.http.get<FaturamentoProfessor[]>(`${this.baseUrl}/professores/faturamentos?${stringify(params)}`);
  }

  reenviarConfirmacaoEmail(id: number) {
    return this.http.post(`${this.baseUrl}/professores/${id}/confirmacao-email`, {});
  }

  getOpcoesSite() {
    return this.http.get<any[]>(`${this.baseUrl}/professores/sites`);
  }

  getAulas(pageNumber: number, pageSize: number, site: number, modulo: number) {
    return this.http.get<any[]>(
      `${this.baseUrl}/professores/aulas?${stringify({ pageNumber, pageSize, site, modulo })}`,
      {
        observe: 'response',
      }
    );
  }

  getPagamentos(site: any, mes: any, ano: any) {
    const data = this.getDataFormatada(ano, mes);
    return this.http.get<any[]>(`${this.baseUrl}/professores/pagamentos?${stringify({ site, data })}`);
  }
}
