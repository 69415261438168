import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Aula } from 'src/app/models/aula';
import { Opcao } from 'src/app/models/opcao';
import { stringify } from 'querystring';
import { AvaliacaoAula } from 'src/app/models/avaliacao-aula';
import { HistoricoAvaliacao } from 'src/app/models/historico-avaliacao';

@Injectable({
  providedIn: 'root',
})
export class AulaService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  getAulas(pageNumber: number, pageSize: number, modulo: any, subModulo?: any) {
    let params = null;
    if (subModulo) {
      params = new HttpParams().set('subModulo', subModulo);
    }
    return this.http.get<Aula[]>(`${this.baseUrl}/aulas?${stringify({ pageNumber, pageSize, modulo })}`, {
      params,
      observe: 'response',
    });
  }

  getOpcoesAula(modulo: any, subModulo?: any) {
    let params = null;
    if (subModulo) {
      params = new HttpParams().set('subModulo', subModulo);
    }
    return this.http.get<Opcao[]>(`${this.baseUrl}/aulas/opcoes-aula?modulo=${modulo}`, { params });
  }

  getAulasOrdenadas(curso: any) {
    return this.http.get<Aula[]>(`${this.baseUrl}/aulas/ordenacao?curso=${curso}`);
  }

  getAula(id: number) {
    return this.http.get<Aula>(`${this.baseUrl}/aulas/${id}`);
  }

  salvar(aula: Aula) {
    return this.http.post(`${this.baseUrl}/aulas`, aula);
  }

  atualizar(aula: Aula) {
    return this.http.put(`${this.baseUrl}/aulas/${aula.id}`, aula);
  }

  remover(id: number) {
    return this.http.delete(`${this.baseUrl}/aulas/${id}`);
  }

  atualizarOrdenacao(aulas: Aula[]) {
    return this.http.put(`${this.baseUrl}/aulas/ordenacao`, aulas);
  }

  getAulasDisponiveisCurso(curso: any, modulo: any) {
    return this.http.get<Opcao[]>(`${this.baseUrl}/aulas/curso?${stringify({ id: curso, modulo })}`);
  }

  getAvaliacoes(pageNumber: number, pageSize: number, modulo: any) {
    return this.http.get<AvaliacaoAula[]>(
      `${this.baseUrl}/aulas/avaliacoes?${stringify({ pageNumber, pageSize, modulo })}`,
      {
        observe: 'response',
      }
    );
  }

  resetAvaliacoes(id: number, codigo: string) {
    return this.http.post<any>(`${this.baseUrl}/aulas/reset-avaliacoes`, {
      aulaId: id,
      codigo,
    });
  }

  getHistoricoAvaliacoes(id: number) {
    return this.http.get<HistoricoAvaliacao[]>(`${this.baseUrl}/aulas/${id}/historico-avaliacoes`);
  }
}
